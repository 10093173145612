<template>
  <el-dialog title="批量退款" :visible.sync="value" :close-on-click-modal="false" :before-close="dialogClose" width="420px">
    <el-form ref="form" :model="form" :rules="rules" label-width="95px">
      <el-row>
        <el-col >
          <el-form-item label="退款信息">
            <label style="color:red;">当前订单最多可退{{returnOrderInfo.refundableAmount}}元</label>
          </el-form-item>
        </el-col>
        <el-col >
          <el-form-item label="退款金额" prop="refundMoney" >
            <el-input placeholder="请输入" v-model="form.refundMoney" maxlength="20" ></el-input>
          </el-form-item>
        </el-col>

        <el-col >
          <el-form-item label="继续配送" prop="endTrading" >
            <el-select v-model="form.endTrading" placeholder="请选择" style="width:100%"  >
              <el-option label="继续配送" value="1"></el-option>
              <el-option label="结束配送" value="2"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col >
          <el-form-item label="已配送天数" prop="useDays" >
            <el-input placeholder="请输入" v-model="form.useDays" maxlength="20" ></el-input>
          </el-form-item>
        </el-col>
        <el-col >
          <el-form-item label="剩余天数" prop="surplusDays">
            <el-input placeholder="请输入" v-model="form.surplusDays" maxlength="20" ></el-input>
          </el-form-item>
        </el-col>        
        
        <el-col >
          <el-form-item label="退款原因" >
           <el-input type='textarea' placeholder="请输入退款原因" v-model="form.refundReason" maxlength="200"></el-input>
          </el-form-item>
        </el-col>
        <el-col >
          <el-button @click="dialogClose">关闭</el-button>
          <el-button type="primary" v-debounce="onSubmit" >确定退款</el-button>
        </el-col>
      </el-row>
    </el-form>
  </el-dialog>
</template>
<script>

import { applyRefund } from '@/api/index'
export default {
  name: 'BrandForm',
  components: {  },
  props: {
    value:{
      type: Boolean,
      default: false
    },
    returnOrderInfo:{
      type: Object,
      default: () =>{}
    }
  },
  data() {
    return {
      form: {
        code:'',
        useDays:'',
        surplusDays:'',
        refundReason:'',
        refundMoney:'',
        endTrading:''
      },
      rules: {
        refundMoney: [
          { required: true, message: '请输入退款金额', trigger: 'blur' }
        ],
        useDays: [
          { required: true, message: '该项不能为空', trigger: 'blur' }
        ],
        surplusDays: [
          { required: true, message: '该项不能为空', trigger: 'blur' }
        ],
        refundMoney: [
          { required: true, message: '该项不能为空', trigger: 'blur' }
        ],
        endTrading: [
          { required: true, message: '该项不能为空', trigger: 'blur' }
        ],
      },

    }
  },
  computed: {
  },
  created() {
    this.form.code = this.returnOrderInfo.code
  },
  methods: {
    dialogClose(type) {
      if (type == 1) {
        this.$emit('success')
      }
      this.$emit('input', false)
    },
    onSubmit() {
      if (!this.$validate('form')) return
      if(this.form.refundMoney>this.returnOrderInfo.refundableAmount){
        this.$message.error('退款金额不能大于'+this.returnOrderInfo.refundableAmount)
        return;
      }
      let form = JSON.parse(JSON.stringify(this.form));
      let success = response => {
        if (response.code == 200) {
          let message = '成功'+response.data.successCount+'条，失败'+response.data.errorCount+'条,明细请前往退款记录中查看。'

          this.$alert(message, '退款结果', {
            confirmButtonText: '确定'
          });

          this.dialogClose(1);
        } else {
          this.$message.error({
            message: response.msg || response.error_msg,
            duration: 1500
          });
        }
      }
      applyRefund(form).then(success)
    }
  },

}
</script>